<template>
  <div>
    <Header :has-login="hasLogin" :username="user.username"/>

    <div class="block">
      <el-timeline class="timeline">
        <el-timeline-item :timestamp="diary.createTime" placement="top" v-for="diary in diarys">
          <el-card>
            <h4>
              <router-link :to="{name: 'DiaryDetail', params: {diaryId: diary.id}}">
                {{diary.title}}
              </router-link>
              <div style="color: #0088ff; font-size: medium;  padding: 3px 0" type="text">提交人：{{diary.username}}</div>
            </h4>
            <el-tag v-show="diary.status==1" type="success" style="float:right; font-size: large">已审批</el-tag>
            <el-tag v-show="diary.status==0" type="info" style="float:right; font-size: large">未审批</el-tag>
            <el-tag v-show="diary.status==2" type="danger" style="float:right; font-size: large">已退审</el-tag>
            <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{diary.content}}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>

      <el-pagination class="mpage"
                     background
                     layout="prev, pager, next"
                     :current-page="currentPage"
                     :page-size="size"
                     @current-change="page"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  name: "Todo",
  components: {Header},
  data() {
    return {
      diarys: {},
      currentPage: 1,
      total: 0,
      size: 5,
      user: {
        id: '',
        username: '',
        avatar: '',
        email: ""
      },
      hasLogin:false
    }
  },
  methods: {
    page(currentPage) {
      console.log("总页数" + this.total)
      this.$axios.get("/diary/todo",
         {
           headers: {
          "Authorization": localStorage.getItem("token")
        },
        params: {
          userId: this.$store.getters.getUser.id,
          currentPage: currentPage,
          size: 5
                }
         }
      ).then(
          res => {
            console.log(res)
            this.diarys = res.data.data.list
            this.currentPage = res.data.data.pageNum
            this.total = res.data.data.total
          }
      )
    }
  },
  created() {
    this.page(1)
    if (this.$store.getters.getUser) {
      console.log("用户信息id：" + this.$store.getters.getUser.id)
      this.user.id = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      this.user.avatar = this.$store.getters.getUser.avatar
      this.user.email = this.$store.getters.getUser.email

      this.hasLogin = true

    }
  }
}
</script>

<style scoped>

.block {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.timeline {
  margin-right: 30px;
  margin-top: 20px;
}

.mpage {
  text-align: center;
}


</style>
